<template>
  <div class="create-content-main">
    <div class="create-form">
      <div style="position: relative; width: 100%; display: block">
        <div class="mb-2 row">
          <h2
            class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
          ></h2>
          <div
            class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
          >
            <button
              v-on:click="returnList()"
              class="button-back-dashboard back-btn"
            >
              戻る
            </button>
          </div>
        </div>
        <div class="customContentMoblie">
          <div class="row mx-0">
            <div class="col-3 px-1">
              <img
                v-if="listMessageByChat && listMessageByChat.image"
                :src="env + listMessageByChat.image"
                width="70px"
                height="70px"
              />
              <img
                v-else
                src="@/assets/img/no-image.jpg"
                width="70px"
                height="70px"
              />
            </div>
            <div class="col-auto">
              <div class="mt-3">{{ listMessageByChat.title }}</div>
            </div>
          </div>
        </div>
        <div
          style="height: 30px"
          class="d-flex flex-nowrap align-items-center px-3"
        >
          <!-- <a @click="BackNoteAndChat(0)" style="cursor: pointer">ノート/質問</a>
          <span>>ユーザー名</span> -->
        </div>
        <div>
          <div
            class="chat-mobile"
            style="
              background-color: #668ad8b8;
              overflow: auto;
              height: calc(100vh - (140px + 111px));
              position: relative;
            "
            id="bodyChat"
          >
            <div
              class=""
              style="padding: 10px 12px"
              v-for="(itemMess, index) in listMessageByChat.message"
              v-bind:key="index"
            >
              <!-- UI Message USER -->
              <div
                v-if="itemMess.user_type === 4 || itemMess.user_type === 5"
                class="d-flex flex-nowrap align-items-center"
              >
                <div class="mt-2 ml-2">
                  <div class="custom-name-user">
                    {{ itemMess.last_name }} {{ itemMess.first_name }}
                  </div>
                  <div class="customChatUser">
                    <div class="d-flex flex-column p-3">
                      <div style="word-wrap: break-word">
                        <div v-html="replaceMessage(itemMess.message)"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End UI Message USER -->

              <!-- UI Message ADMIN -->
              <div
                class="d-flex flex-nowrap flex-row-reverse align-items-center"
                v-if="itemMess.user_type !== 4 && itemMess.user_type !== 5"
              >
                <div class="customChatAdmin mt-3">
                  <div class="d-flex flex-column p-3">
                    <div style="word-wrap: break-word">
                      <div v-html="replaceMessage(itemMess.message)"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="pr-3 mt-3 del-mess"
                  v-if="checkDelete != itemMess.id"
                  @click="deletMessage(itemMess.id)"
                >
                  <i class="fas fa-times" style="color: white"></i>
                </div>
              </div>
              <!-- End UI Message ADMIN -->
            </div>
          </div>
          <div
            class="custom-control CustomInputChat w-100 pl-0"
            style="background-color: white; border-style: groove"
          >
            <div
              class="p-1 d-flex flex-nowrap justify-content-center align-items-center"
            >
              <textarea
                class="hoverMouse pl-0 px-4 form-control"
                v-model="titleSendMessage"
                placeholder="返信内容を記載"
                style="
                  border: 1px solid #fff;
                  width: 90%;
                  background-color: #d8dbe0;
                  border-radius: 30px;
                  resize: none;
                "
                rows="3"
                no-resize
                @keyup="checkLimitLength"
                @keydown.enter.exact.prevent="titleSendMessage += '\n'"
                @keydown.enter.shift.exact.prevent="ClickSendMessage()"
              ></textarea>
              <div
                style="width: 10%"
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  v-on:click="ClickSendMessage()"
                  src="@/assets/img/send.png"
                  style="width: 18px; height: 20px; cursor: pointer"
                />
                <div>返信</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
function initialState() {
  return {
    titleSendMessage: "",
    env: Constants.URL_BE + "/storage/",
    shop_id: localStorage.getItem(Constants.SHOP_ID),
    listMessageByChat: {},
    checkDelete: null,
    previousTitleSendMessage: null,
  };
}
export default {
  name: "CreateCategory",
  data() {
    return initialState();
  },
  created() {
    this.getDetailMessageByChatID();
  },
  computed: {
    ...mapGetters(["success", "message", "error", "listMessageByChatID"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    listMessageByChatID() {
      this.listMessageByChat = this.listMessageByChatID;
      this.idChat = this.listMessageByChatID.id;
    },
  },
  methods: {
    ...mapActions({
      getMessageByChatIDAdmin: "getMessageByChatIDAdmin",
      sendMessageAdmin: "sendMessageAdmin",
      deleteMessageByIDAdmin: "deleteMessageByIDAdmin",
    }),
    async getDetailMessageByChatID() {
      const dataRequest = {
        idChat: this.$route.params.id,
        shop_id: this.shop_id,
      };
      let response = await this.getMessageByChatIDAdmin(dataRequest);
      if (!response.success) {
        this.$store.commit("set", ["error", true]);
        this.$store.commit("set", ["message", response.message]);
      }
    },
    async ClickSendMessage() {
      if (!this.titleSendMessage || !this.titleSendMessage.trim()) {
        this.$toasted.error("文字が入力されていません");
        return;
      }
      const data = {
        shop_id: this.shop_id,
        chat_id: this.$route.params.id,
        message: this.titleSendMessage,
      };
      const dataRepond = await this.sendMessageAdmin(data);
      if (dataRepond.success) {
        this.titleSendMessage = "";
        const dataRequest = {
          id: this.$route.params.id,
        };
        await this.getDetailMessageByChatID(dataRequest);
        this.gotoBottom();
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    async deletMessage(id) {
      this.checkDelete = id;
      const dataRequest = {
        id: id,
        shop_id: this.$route.params.shopId,
      };
      const dataRepond = await this.deleteMessageByIDAdmin(dataRequest);
      if (dataRepond.success) {
        this.titleSendMessage = "";
        const dataRequest = {
          id: this.idChat,
        };
        await this.getDetailMessageByChatID(dataRequest);
        // this.$toasted.success(dataRepond.message);
      }
    },
    gotoBottom() {
      var element = document.getElementById("bodyChat");
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    checkLimitLength(event) {
      let newValue = event.target.value;
      if (newValue.length > Constants.LIMIT_MESSAGE) {
        event.preventDefault();
        this.titleSendMessage = this.previousTitleSendMessage;
        this.$store.commit("set", ["message", "メッセージが長すぎます"]);
        this.$store.commit("set", ["error", true]);
      } else {
        this.titleSendMessage = newValue;
        this.previousTitleSendMessage = newValue;
      }
    },
    replaceMessage(message) {
      if (!message || !message.trim()) return "<div>" + "<div>";
      let messageList = message.split("\n");
      let html = "";
      messageList.forEach((elem) => {
        if (elem.includes("http://") || elem.includes("https://")) {
          let elemList = elem.split(" ");
          elemList.forEach((val) => {
            if (val.includes("http://") || val.includes("https://")) {
              html += '<a href="' + val + '" target="_blank">' + val + "</a>";
            } else {
              html += "<div>" + val + "<div>";
            }
          });
        } else {
          html += "<div>" + elem + "<div>";
        }
      });
      return html;
    },
  },
};
</script>
<style lang="scss" scoped>
.CustomInputChat {
  bottom: 0;
}
.customChatAdmin {
  width: 300px;
  border-radius: 7px;
  background-color: #fff056;
  position: relative;
  margin-right: 20px;
}
.customChatAdmin:before {
  content: "";
  position: absolute;
  right: 100%;
  top: -10px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid #fff056;
  border-bottom: 13px solid transparent;
  left: 95%;
  transform: rotate(128deg);
}
.customChatUser {
  width: 300px;
  border-radius: 7px;
  background-color: white;
  position: relative;
  margin-left: 47px;
}
.customChatUser:before {
  content: "";
  position: absolute;
  right: 100%;
  top: -9px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid white;
  border-bottom: 13px solid transparent;
  left: -9px;
  transform: rotate(57deg);
}
.customContentMoblie {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #d1d1d1;
  padding: 15px 0 15px 15px;
  /* background-image: url(/img/icon_arrow01.939c2d50.png); */
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
  border-radius: 20px;
  box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.75);
  width: 400px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1 !important;
  @media (max-width: 760px) {
    width: 90%;
  }
}
.hoverMouse::-webkit-scrollbar {
  display: none;
}
.hoverMouse:focus {
  border: 1px solid #ccc !important;
  box-shadow: none;
}
.hoverMouse:hover {
  border: 1px solid #ccc !important;
}
.chat-mobile {
  @media (max-width: 768px) {
    margin-top: 55px;
  }
}
.custom-name-user {
  background-color: #dfd9d9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 6px;
  font-size: 12px;
  word-break: break-word;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
